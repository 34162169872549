<template>
  <div class="order-part-tabs">
    <div class="icon-bar">
      <i class="el-icon-s-data icon-item" :class="{ fontClass: isOpen === '1' }" @click="openTabs('1')"></i>
      <i class="el-icon-s-grid icon-item" :class="{ fontClass: isOpen === '2' }" @click="openTabs('2')"></i>
    </div>
    <div class="tab-part">
      <div class="tab-part-piture" v-if="isOpen === '1'">
        <MyChart :data="chartData" fncName="initOrderLineOption" @lineStarAndtEnd="lineStarAndtEnd"></MyChart>
      </div>
      <div class="tab-part-table" v-else>
        <CommonTable
          height="auto"
          :selection="false"
          :cols="cols"
          :infoData="data"
          v-loading="tableLoading"
          class="table-bottom-margin"
        ></CommonTable>
        <div class="pagination-position">
          <PaginationBar
            @refreshTableEventFun="sup_this.refreshTableEventFun"
            :total="sup_this.total"
            :size="sup_this.size"
            :page="sup_this.page"
          ></PaginationBar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MyChart from '../chartPart/MyChart.vue'
import { deepClone } from '@/components/avue/utils/util'
export default {
  components: {
    MyChart
  },
  props: {
    cols: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    },
    query: {
      type: Object,
      required: true
    },
    tableLoading: {
      type: Boolean
    }
  },
  data() {
    return {
      isOpen: '2',
      chartData: []
    }
  },
  computed: {},
  watch: {
    data: {
      handler(newVal) {
        this.chartData = newVal || []
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    openTabs(val) {
      this.isOpen = val
    },
    lineStarAndtEnd(val) {
      if (val.start === 0) {
        this.sup_this.page++
        this.sup_this.init()
        if (this.data.length === 0) {
          this.sup_this.page--
          this.$message.warning('没有更早的数据')
        }
      }
      if (val.end === 100) {
        if (this.sup_this.page > 1) {
          this.sup_this.page--
          this.sup_this.init()
        } else {
          this.$message.warning('已是最新数据')
          return
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.order-part-tabs {
  .icon-bar {
    padding: 10px;
    overflow: hidden;
    .icon-item {
      float: right;
      width: 20px;
      height: 20px;
      line-height: 20px;
      margin-left: 10px;
    }
    .fontClass {
      color: $color-primary;
    }
  }
  .tab-part-piture {
    height: 380px;
  }
  .pagination-position {
    position: fixed;
    overflow: hidden;
    bottom: 20px;
    height: 48px;
    left: 70%;
    border-radius: 10px;
    background-color: #fff;
    transform: translateX(-50%);
  }
  .table-bottom-margin {
    margin-bottom: 30px;
  }
}
</style>

import { deepClone } from '@/components/avue/utils/util'
import cloneDeep from 'lodash/cloneDeep'
//销售数量种类占比饼状图配置项
export const pieTotalOption = (data) => {
  const colorList = [
    '#3841DB',
    '#00BC93',
    '#0078FF',
    '#FFB200',
    '#5470c6',
    '#91cc75',
    '#fac858',
    '#ee6666',
    '#73c0de',
    '#3ba272',
    '#fc8452'
  ]
  const colorLen = colorList.length
  const legendData = cloneDeep(data)
  legendData.map((item, index) => {
    item.textStyle = {
      color: colorList[index % colorLen] // 图例文字颜色
    }
  })

  const option = {
    tooltip: {
      trigger: 'item'
    },
    color: colorList,
    legend: {
      type: 'scroll',
      orient: 'vertical',
      right: 10,
      top: 20,
      itemWidth: 10,
      itemHeight: 10,
      data: legendData,

      formatter: function (name) {
        var data = option.series[0].data
        var total = 0
        var tarValue
        for (var i = 0; i < data.length; i++) {
          total += data[i].value
          if (data[i].name == name) {
            tarValue = data[i].value
          }
        }
        var v = tarValue
        var p = Math.round((tarValue / total) * 100)
        if(p !== p) {
          p = 0
        }
        return [`{a|${v} (${p}%) }`, `{b|${name}}`].join('\n')
      },

      textStyle: {
        rich: {
          a: {
            lineHeight: 25,
            fontSize: 15
          },
          b: {
            color: '#495060'
          }
        }
      }
    },
    series: [
      {
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 2,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center',
          normal: {
            show: true,
            position: 'center',
            fontWeight: 'bold',
            formatter: function () {
              const numArr = data.map(({ value }) => {
                return value
              })
              var sum = numArr.reduce((pre, cur) => {
                return (Number(pre) + Number(cur)).toFixed(2)
              })
              return `产品销售总额 \r\n\r\n ${sum}`
            },
            textStyle: {
              fontSize: 12,
              color: '#3841DB'
            }
          }
        },
        labelLine: {
          show: false
        },
        data
      }
    ]
  }
  return option
}
//柱状图配置项
export const zhuOption = (data) => {
  //对data进行拆分
  const { orderData, timeData, saleData } = data
  const option = {
    echartsOption: {
      title: {
        text: ''
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: false, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      legend: {
        data: ['销售数量', '订单数量']
      },
      xAxis: {
        type: 'category',
        name: '时间',
        data: timeData || ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
      },
      yAxis: {
        type: 'value',
        name: '数量'
      },
      series: [
        {
          name: '销售数量',
          type: 'bar',
          data: saleData || [5, 20, 36, 10, 10, 20, 10, 25, 36, 14, 24, 23],
          barGap: '0%',
          emphasis: {
            focus: 'series'
          }
        },
        {
          name: '订单数量',
          type: 'bar',
          data: orderData || [7, 10, 26, 10, 20, 10, 15, 24, 35, 25, 14, 36],
          emphasis: {
            focus: 'series'
          }
        }
      ]
    }
  }
  return option
}

//折线图配置项
export const lineOption = (data) => {
  const newDate = deepClone(data).reverse()
  //需要对数据进行重构
  const timeData = newDate.map(({ orderDate }) => {
    return orderDate
  })
  const orderData = newDate.map(({ orderCount }) => {
    return orderCount
  })
  const productData = newDate.map(({ productAmount }) => {
    return productAmount
  })
  const option = {
    backgroundColor: '#fff',
    color: ['#73A0FA', '#73DEB3', '#FFB761'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        },
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    dataZoom: [{ show: 'false', type: 'inside', start: 0, end: 100 }],
    grid: {
      left: '25',
      right: '25',
      bottom: '24',
      top: '75',
      containLabel: true
    },
    legend: {
      data: ['订单数量', '销售额'],
      orient: 'horizontal',
      icon: 'circle',
      show: true,
      left: 800,
      top: 25
    },
    xAxis: {
      type: 'category',
      data: timeData || ['02-07', '02-08', '02-09', '02-10', '02-11', '02-12', '02-13'],
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      interval: 0
    },
    yAxis: {
      type: 'value',

      axisLabel: {
        color: '#999',
        textStyle: {
          fontSize: 12
        }
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#F3F4F4'
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      }
    },
    series: [
      {
        name: '订单数量',
        type: 'line',
        smooth: true,
        data: orderData || [10, 13, 120, 210, 124, 114, 136]
      },
      {
        name: '销售额',
        type: 'line',
        smooth: true,
        data: productData || [50, 23, 20, 20, 24, 14, 36]
      }
    ]
  }
  return option
}

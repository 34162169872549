<template>
  <div ref="chart" style="width: 100%; height: 100%"></div>
</template>

<script>
const echarts = require('echarts')
import { pieTotalOption, lineOption } from './option'
export default {
  props: {
    fncName: {
      type: String,
      default: 'initPieTotalOption'
    },
    data: {
      type: Array | Object,
      default: () => []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.myChart = echarts.init(this.$refs.chart)
      this[this.fncName]()
    })
    window.addEventListener('resize', this.refresh)
  },
  destroyed() {
    window.removeEventListener('resize', this.refresh)
  },
  watch: {
    data: {
      handler() {
        this.refresh()
      },
      deep: true
    },
    siderBarStatus() {
      this.refresh()
    }
  },
  methods: {
    refresh() {
      this.myChart.dispose()
      this.$nextTick(() => {
        this.myChart = echarts.init(this.$refs.chart)
        this[this.fncName]()
      })
    },
    //初始化销售数量种类占比饼状图
    initPieTotalOption() {
      this.myChart.setOption(pieTotalOption(this.data))
    },
    //初始化订单折线图
    initOrderLineOption() {
      this.myChart.setOption(lineOption(this.data))
      this.myChart.on('datazoom', ({ batch }) => {
        // console.log(batch[0].start, batch[0].end)
        this.$emit('lineStarAndtEnd', { start: batch[0].start, end: batch[0].end })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-wrapper {
  div {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
</style>
